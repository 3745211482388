import _get from 'lodash.get'
import { DateTime } from 'luxon'
import { formatCurrency } from '@/utils/NumberFunctions'

export default {
  methods: {
    isLineDifferentFromLastCommunication(item) {
      try {
        return this.isOrmLineDifferentFromLastCommunication(item)
      } catch(ex) {
        console.log(ex)
        return false
      }
    },
    isFieldDifferenceFromLastCommunication(item, fieldName) {
      try {
        return this.isOrmFieldDifferenceFromLastCommunication(item, fieldName)
      } catch(ex) {
        console.log(ex)
        return false
      }
    },
    getCommunicationFieldsToCheck(item) {
      let target = this.getTargetEntity()

      if (target.orm !== null) {
        return this.getOrmFieldsToCheck(item)
      }

      return []
    },
    lookupItemElementName(value) {
      let lookup = {
        0: 'MECH',
        1: 'Misc',
        2: 'PAINT',
        3: 'PaintMaterials',
        4: 'Part',
        5: 'RR',
        6: 'REP',
        7: 'Towing',
        'Sublet': 'SUBL'
      }

      return lookup[value]
    },
    getOrmFieldsToCheck(item) {
      let fieldsToCheck = {
        //lineNumber: 'itemNo',
        //itemNo: 'code',
        itemDesc: 'description',
        itemType: (item) => {
          let name = item.itemElementName
          if(name == '1') {
            if(item?.item?.sublet !== null) {
              name = 'Sublet'
            }
          }

          return this.lookupItemElementName(name).toUpperCase()
        }
      }

      if(item.itemType == 'PART') {
        fieldsToCheck['itemQuantity'] = 'item.partQuantity'
        fieldsToCheck['value'] = 'item.partValue'
        fieldsToCheck['partNo'] = 'item.partSource.item.partNumber'
      } else if(item.itemType == 'MISC') {
        fieldsToCheck['value'] = 'quoteItemValue'
      } else if(item.itemType == 'SUBL') {
        fieldsToCheck['value'] = 'quoteItemValue'
      } else {
        fieldsToCheck['hourValue'] = 'item.hours'
        fieldsToCheck['dollarValue'] = 'quoteItemValue'
      }

      return fieldsToCheck
    },
    isOrmFieldDifferenceFromLastCommunication(item, fieldName) {

      let target = this.getTargetEntity()

      if (target.orm === null) {
        return false
      }

      let fieldsToCheck = this.getOrmFieldsToCheck(item)

      let exFieldName = fieldsToCheck[fieldName]
      const historyLine = this.getLastOrmHistoryLine(item)
      let exItem = historyLine ? historyLine.record : null

      let value1 = _get(item, fieldName)

      let value2 = null
      if(typeof exFieldName === 'function') {
        value2 = exFieldName(exItem)
      } else {
        value2 = _get(exItem, exFieldName)
      }

      // IGNORE CASE
      let isDifferent = (value1+'').toLocaleUpperCase().trim() != (value2+'').toLocaleUpperCase().trim()

      if(isDifferent) {
        console.log(item.itemDesc, item.itemType,fieldName,value1,value2)
      }

      return isDifferent
    },
    isOrmLineDifferentFromLastCommunication(item) {

      let target = this.getTargetEntity()

      if (target.orm === null) {
        return false
      }

      let fieldsToCheck = this.getCommunicationFieldsToCheck(item)
      for(let fieldName of Object.keys(fieldsToCheck)) {
        if(this.isOrmFieldDifferenceFromLastCommunication(item, fieldName)) {
          return true
        }
      }

      return false
    },
    getLastOrmHistoryLine(item) {

      let target = this.getTargetEntity()

      if(target.ormHistory === undefined || target.ormHistory[item.lineNumber] === undefined) {
        return null
      }

      return target.ormHistory[item.lineNumber].slice(-1)[0]
    },
    getTargetEntity() {
      var target = this.innerValue
      if(this.innerValue === undefined) {
        target = this.value
      }
      return target
    },
    getTooltipCommunicationHistoryOptionsForField(item, fieldname) {
      return { allowHTML: true, enabled: this.isFieldDifferenceFromLastCommunication(item, fieldname), maxWidth: 'none'}
    },
    getTooltipCommunicationHistoryForField(item, fieldName) {
      try {

        let cleanNameLookup = {
          itemDesc: 'Description',
          itemType: 'Type'
        }

        if(item.itemType == 'PART') {
          cleanNameLookup['itemQuantity'] = 'Quantity'
          cleanNameLookup['value'] = 'Total'
          cleanNameLookup['partNo'] = 'Part No.'
        } else if(item.itemType == 'MISC') {
          cleanNameLookup['value'] = 'Total'
        } else if(item.itemType == 'SUBL') {
          cleanNameLookup['dollarValue'] = 'Total'
        } else {
          cleanNameLookup['hourValue'] = 'Hours'
          cleanNameLookup['dollarValue'] = 'Total'
        }

        let namesToFormat = {}
        namesToFormat['dollarValue'] = (value)=>formatCurrency(value, this.$userInfo.locale)
        namesToFormat['value'] = (value)=>formatCurrency(value, this.$userInfo.locale)

        let fieldsToCheck = this.getCommunicationFieldsToCheck(item)
        let target = this.getTargetEntity()
        let history = (target.ormHistory[item.lineNumber]) || []

        // DO HEADINGS
        let headings = ['Date','Type']
        for(let fieldName of Object.keys(fieldsToCheck)) {
          if(cleanNameLookup[fieldName] !== undefined) {
            headings.push(cleanNameLookup[fieldName])
          } else {
            headings.push(fieldName)
          }
        }

        // DO LINES
        //let lastAuthValue = null
        let output = []
        for(let record of history) {
          let historyLine = record.record

          lineData = [DateTime.fromISO(record.datetime).toLocaleString(), (record.isRequest) ? 'Quoted' : 'Authorised' ]
          for(let fieldName of Object.keys(fieldsToCheck)) {

            var exFieldName = fieldsToCheck[fieldName]
            let value = ''
            if(typeof exFieldName === 'function') {
              value = exFieldName(historyLine)
            } else {
              value = _get(historyLine, exFieldName)
            }

            if(namesToFormat[fieldName] !== undefined) {
              value = namesToFormat[fieldName](value)
            }

            lineData.push(value)
          }

          output.push('<td>'+lineData.join('</td><td>')+'</td>')
        }

        // current
        let lineData = ['Now','Current']
        for(let fieldName of Object.keys(fieldsToCheck)) {
          let value = _get(item, fieldName)
          if(namesToFormat[fieldName] !== undefined) {
            value = namesToFormat[fieldName](value)
          }
          lineData.push(value)
        }
        output.push('<td>'+lineData.join('</td><td>')+'</td>')
        output.reverse()

        output = [...new Set(output)]
        return '<table class="table is-striped is-narrow"><tr><th>'+headings.join('</th><th>')+'</th></tr><tr class="has-background-warning-light">'+output.join('</tr><tr>')+'</tr></table>'
      } catch(ex) {
        console.log(ex)
        return ''
      }
    }
  }
}