import { QuotingMethodTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteItemsMixin from './QuoteItemsMixin'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  mixins: [QuoteTotalsMethodMixin, QuoteItemsMixin],
  computed: {

  },
  methods: {
    updateValue(index, item) {
      // if (item && item.deleteOtherItem && item.hourValue > 0) {
      //   item.hourValue = item.hourValue * -1
      // }
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      }
      this.updateTotal()
    },
    getLabourRate(code, rates, shopRate, quoteId) {
      if (!rates) {
        return {}
      }
      var rate = rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === code)
        const newRate = {
          quoteId: quoteId,
          labourCodeId: code,
          labourTypeId: labourType.labourTypeId,
          rate: this.isNtar ? shopRate : 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          quoteVersion: 0,
          deleted: false
        }
        rates.push(newRate)
        rate = newRate
      }
      return rate
    }
  }
}
