<template>

  <div class="item-row-quote-builder-sublet quote-builder-item-row"
    :id="item.quoteItemId"
    style="display: grid;"
    :class="[{'has-background-grey-lighter is-selected' : isSelected, 'is-open' : isSelected && !isFullWidth, 'is-support-user' : isSupportUser}, defaultRowColourClass(item, getItemAssessments(item), displayDeleted), rowDisplayStatus(item, getItemAssessments(item))]"
    @click="event => handleClick(event)">

    <!--------------------------------------------------------------- Main Grid --------------------------------------->
    <quote-builder-sublet-item-template :is-full-width="isFullWidth"
      :is-fully-expanded="isSelected && !isFullWidth"
      @handleClick="handleClick">
      <!------- Description / partnumber ------->
      <template v-slot:description>

        <div class="cell is-col-span-11 is-flex is-flex-direction-column"
          :class="defaultCellColourClass(item,'itemDesc')"
          :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
          <item-description-autocomplete v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && $company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
            v-model="item.itemDesc"
            v-focus-next:callback="handleTransitionNextRow"
            schedule-id=""
            body-id=""
            :id="item.quoteItemId + '-itemDesc'"
            :class-prop="(!isValidItemDesc(item.quoteItemId) ? 'is-danger ': '') + defaultRowColourClass(item, getItemAssessments(item))"
            :item-type="itemTypes.SUBL"
            :times-style="itemTypes.SUBL" />
          <input v-else-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !$company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :id="item.quoteItemId + 'itemDesc'"
            :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item Description"
            v-model="item.itemDesc">
          <span v-else
            :id="item.quoteItemId + '-itemDesc'"
            class="is-quote-item-field-editable"
            :class="{'is-italic has-text-danger': !item.itemDesc}">
            {{ item.itemDesc || 'Description' }}
          </span>
        </div>
      </template>

      <!-- reports -->
      <template v-slot:report_blend>
        <!------------------- Report ------------>
        <div class="is-flex is-clickable is-align-items-center"
          :class="{'disabled' : item.deleteOtherItem || !canMakeReportOnly(item)}"
          @click="updateReport(item, $event)">
          <span v-tippy="{ theme: 'secondary', enabled: true }"
            content="Report"
            :class="item.reportOnly ? 'is-dark ' : 'is-light'"
            class="tag is-primary p-1 px-2">
            R
          </span>
        </div>
      </template>

      <!----------------- Type  ------------------->
      <template v-slot:sublet_type>
        <div v-if="isSelected && sublets && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
          class="select">
          <select v-model="item.subletTypeId"
            placeholder="Sublet Type"
            v-focus-next:callback="handleTransitionNextRow"
            :id="item.quoteItemId + '-sublet-type'"
            :class="defaultRowColourClass(item, getItemAssessments(item))">

            <option value=""
              selected
              hidden>Please Choose...</option>

            <option v-for="sublet in sublets"
              :value="sublet.subletTypeId"
              :key="sublet.subletTypeId">{{ sublet.script }}</option>
          </select>
        </div>
        <span class="is-flex is-justify-content-center"
          :class="{'is-italic': !item.subletType}"
          :id="item.quoteItemId + '-sublet-type'"
          v-else>{{ item.subletType || '-' }}</span>
      </template>

      <!----------------- Vendor  ------------------->
      <template v-slot:sublet_vendor>
        <multiselect v-if="isSelected && vendors.length && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
          :class="defaultRowColourClass(item, getItemAssessments(item))"
          deselect-label="Can't remove this value"
          v-model="selectedVendor"
          v-focus-next:callback="handleTransitionNextRow"
          track-by="id"
          label="name"
          placeholder="Select a Vendor"
          :options="vendorsList"
          :loading="isVendorLoading"
          :internal-search="false"
          @search-change="searchVendor($event)"
          @select="selectVendor($event)"
          :searchable="true"
          :show-labels="false"
          :allow-empty="true">
          <template slot="option"
            slot-scope="props">
            <span>{{ props.option.name }}</span>
          </template>
          <span class="has-text-danger"
            slot="noResult">Vendor not found.</span>
        </multiselect>
        <span class="is-flex is-justify-content-center"
          v-else>
          {{ item.vendorName || '-' }}
        </span>
      </template>

      <!--------------- Total Value  ----------------->
      <template v-slot:total_value>
        <div class="is-flex is-justify-content-end"
          :class="defaultCellColourClass(item,'value')"
          :content="getTooltipCommunicationHistoryForField(item, 'value')"
          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
            class="input has-text-right"
            :id="item.quoteItemId + '-value'"
            :class="defaultRowColourClass(item, getItemAssessments(item))"
            v-model.number="item.value"
            v-focus-next:callback="handleTransitionNextRow"
            :minus="innerValue.subQuoteNo > 0"
            :precision="2"
            @input="updateTotal" />
          <span v-else
            :id="item.quoteItemId + '-value'"
            class="is-quote-item-field-editable">
            {{ $filters.formatCurrency(item.value, $userInfo.locale, 2) }}
          </span>
        </div>
      </template>

      <!---------------------- Comment   ---------------->
      <template v-slot:comment
        v-if="item.itemComment">
        <div v-tippy="{ theme: 'secondary', enabled: true, content:item.itemComment }">
          <span class="mdi has-text-primary is-primary mdi-18px mdi-message-reply-text mr-1 is-clickable" />
        </div>
      </template>

      <!----------------- Assessment Status ----------------->
      <template v-slot:assessment_status>
        <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
          :item="item"
          :item-assessments="getItemAssessments(item)"
          :next-assessment-number="nextSupplemetryNo"
          :quoting-method="innerValue.quotingMethod"
          :icon-size="4"
          class="icon" />
      </template>

      <!----------------- Delete Button ----------------->
      <template v-slot:delete_button>
        <button class="button is-ghost is-small  tooltip p-0 mx-1"
          data-tooltip="Delete Labour Item"
          @click="deleteItem(item)">
          <span class="mdi mdi-delete mdi-24px has-text-danger is-clickable" />
        </button>
      </template>

      <!----------------- Drag Handle ----------------->
      <template v-slot:drag_handle>
        <button class="button is-small is-ghost tooltip p-0 item-drag-handle"
          data-tooltip="Drag">
          <span class="mdi mdi-drag-vertical mdi-24px has-text-primary is-clickable" />
        </button>
      </template>

      <!----------------- Line Number ----------------->
      <template v-slot:lineNo>
        <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
          class="input has-text-right"
          :class="defaultRowColourClass(item, getItemAssessments(item))"
          separator=""
          :precision="0"
          v-model.number="item.lineNumber"
          v-focus-next:callback="handleTransitionNextRow" />
        <span v-else>{{ item.lineNumber }}</span>
      </template>

      <!----------------- Item Number ----------------->
      <template v-slot:itemNo>
        <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
          class="input"
          :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
          placeholder="Item No."
          v-model="item.itemNo"
          v-focus-next:callback="handleTransitionNextRow">
        <span v-else>{{ item.itemNo }}</span>
      </template>

      <!----------------- Item Status ----------------->
      <template v-slot:itemStatus>
        <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
          class="input is-capitalized"
          v-model="item.itemStatus"
          v-focus-next:callback="handleTransitionNextRow">
        <span v-else>{{ item.itemStatus }}</span>
      </template>

    </quote-builder-sublet-item-template>
  </div>

</template>

<script>
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import { QuoteItemStatusTypes, PartStatusTypes, PartTypes, ItemTypes } from '@/enums'
import QuoteBuilderSubletItemTemplate from '../../templates/QuoteBuilderSubletItemTemplate.vue'
import { QuoteItemValidationMixin, QuoteItemsMixin, QuoteItemDifferenceMixin } from '../../mixins'
import SupplementaryIcon from '../../../components/SupplementaryIcon.vue'
import ItemDescriptionAutocomplete from '../../../components/ItemDescriptionAutocomplete/ItemDescriptionAutocomplete.vue'
import _debounce from 'lodash/debounce'
import Multiselect from 'vue-multiselect'
import VendorService from '@/views/vendor/VendorService'
import Guid from '@/components/Guid'
import { FocusNext } from '@/components/directives'

export default {
  name: 'QuoteBuilderSubletItem',
  directives: {
    FocusNext
  },
  components: { ItemDescriptionAutocomplete, vueNumeric, QuoteBuilderSubletItemTemplate, SupplementaryIcon, Multiselect },
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  mixins: [QuoteItemsMixin, QuoteItemValidationMixin, QuoteItemDifferenceMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    // in a single row view, the row is full width
    isFullWidth: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    editItemId: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    },
    sublets: {
      type: Array,
      default: () => []
    },
    itemsAssessments: {
      type: Array,
      default: () => []
    },
    vendors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      displayDeleted: false,
      itemType: 'SUBL',
      selectedVendor: null,
      isVendorLoading: false,
      vendorsList: [],
      vendorFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
    }
  },
  computed: {
    // your computed properties go here
    isSelected() {
      return this.item.quoteItemId === this.editItemId
    },
    PartStatusTypes() {
      return PartStatusTypes
    },
    partTypes() {
      return PartTypes
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    },
    itemTypes() {
      return ItemTypes
    },
    isSupportUser() {
      return this.$userInfo.isSupportUser
    }
  },
  watch: {
    'item.subletTypeId': {
      handler: function (newVal, oldVal) {
        this.onSubletTypeChange(this.item)
      }
    },
    vendors: {
      handler: function (newVal, oldVal) {
        if (!this.vendorsList.length) this.vendorsList = newVal
      }
    }
  },
  created() {
    this.vendorsList = this.vendors
    this.getSelectedVendor()
  },
  methods: {
    handleTransitionNextRow() {
      this.$emit('focusNextRow', this.item.quoteItemId)
    },
    async getSelectedVendor() {
      if (this.item.vendorId) {
        const filter = {
          id: this.item.vendorId,
          name: '',
          pageIndex: 1,
          pageSize: 50
        }
        this.selectedVendor = await VendorService.getVendorDropdownProto2(filter)
      }
    },
    searchVendor: _debounce(async function (query) {
      if (!query) {
        this.vendorsList = this.vendors
        return
      }
      this.isVendorLoading = true
      this.vendorFilter.name = query
      this.vendorsList = await VendorService.getVendorDropdownProto2(this.vendorFilter)
      this.isVendorLoading = false
    }, 500),

    async selectVendor(selected) {
      const vendorFilter = {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }

      this.vendorsList = await VendorService.getVendorDropdownProto2(vendorFilter)
      const itemToUpdate = this.innerValue.sublets.find((i) => i.quoteItemId === this.item.quoteItemId)
      itemToUpdate.vendorId = selected.id
      itemToUpdate.vendorName = selected.name
    },

    handleClick(event, close = false) {
      event.stopPropagation()
      this.$emit('itemRowClicked', this.item, event, close)
    },

    updateReport(item, event) {
      // event.stopPropagation()
      if (!item.deleteOtherItem && !this.innerValue.readOnly && !this.readOnlyView && !this.audanetLock && this.canMakeReportOnly(item)) {
        item.reportOnly = !item.reportOnly
      }
    },

    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.sublets
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.sublets.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    getItemAssessments(item) {
      const assessments = this.itemsAssessments
      const itemAssessments = assessments.find((i) => i.quoteItemId == item.quoteItemId)
      if (!!itemAssessments) return itemAssessments.assessments
      return []
    },
    updateTotal() {
      this.$emit('updateTotal')
    },
    onSubletTypeChange(item, event) {
      console.log(item.subletTypeId)

      // find the sublet type
      const subletType = this.sublets.find((sublet) => sublet.subletTypeId === item.subletTypeId)
      if (subletType) {
        const subletItem = this.innerValue.sublets.find((sublet) => sublet.quoteItemId === item.quoteItemId)
        if (subletItem) {
          subletItem.subletType = subletType.script
          console.log(subletType.script)
        }
        // this.item.subletType = subletType.script
        // console.log(subletType)
      }
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.item-row-quote-builder-sublet {
  height: 45px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 5px;
  padding: 2px;

  .center-y {
    display: flex;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }

  &.is-open {
    height: 85px !important;

    &.is-support-user {
      height: 130px !important;
    }
  }
}

/* text overflow ellipses */
.item-row-quote-builder-sublet span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>