<template>

  <!--------------------------------------Full width Mode ------------------>
  <div v-if="isFullWidth"
    class="fixed-grid has-12-cols is-align-self-center"
    @click="event => $emit('handleClick', event)">
    <div class="grid is-align-items-center">

      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6">
        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">

            <!-- Description -->
            <div class="cell is-flex is-flex-direction-column"
              :class="(isPaintItem) ? 'is-col-span-8' : showPaintLoading ? 'is-col-span-9' : 'is-col-span-11'">
              <slot name="description" />
            </div>

            <!----------- Paint  -------------->
            <div class="cell center is-col-span-2"
              v-if="isPaintItem">
              <slot name="paint_group" />
            </div>

            <!-- Blend -->
            <div class="cell is-col-span-1 is-clickable"
              v-if="isPaintItem">
              <slot name="blend" />
            </div>

            <!-- Loading, only for RR is ntar timing -->
            <div class="cell is-col-span-2 is-flex is-align-items-center is-justify-content-center"
              v-if="showPaintLoading">
              <slot name="loading" />
            </div>

            <!-- Report -->
            <div class="cell is-col-span-1 is-clickable">
              <slot name="report" />
            </div>

          </div>
        </div>
      </div>

      <!----------- Rates   -------------->
      <div class="cell">
        <slot name="rates"
          v-if="isQuoteHour" />
      </div>

      <!---------- hour value ------------->
      <div class="cell">
        <slot name="hour_value" />
      </div>

      <!---------- Total value ------------->
      <div class="cell is-col-span-2">
        <slot name="total_value" />
      </div>

      <!---------- Present As ------------->
      <div class="cell">
        <slot name="present_as" />
      </div>

      <!---------- Actions ------------->
      <div class="cell end is-align-items-center">
        <div class="mt-1 is-flex ">
          <slot name="comment" />
          <slot name="assessment_status" />
          <slot name="delete" />
          <slot name="draggable" />
        </div>
      </div>

    </div>
  </div>

  <!------------------------------- Half screen width/ multi col view ------------>
  <div class="fixed-grid has-12-cols is-align-self-center"
    v-else
    @click="event => $emit('handleClick', event)">

    <div class="grid is-align-self-center is-align-items-center"
      style="row-gap: 5px;">
      <!-- Description, belnd and report -->
      <div class="cell is-align-content-center"
        :class="(isPaintItem) || showPaintLoading ? 'is-col-span-4' : 'is-col-span-5'">
        <slot name="description" />
      </div>

      <!-- Blend, only for paint item -->
      <div class="cell is-col-span-1 is-flex is-align-items-center is-justify-content-center"
        v-if="isPaintItem">
        <slot name="blend" />
      </div>

      <!-- Loading, only for RR is ntar timing -->
      <div class="cell is-col-span-1 is-flex is-align-items-center is-justify-content-center"
        v-if="showPaintLoading">
        <slot name="loading" />
      </div>

      <!----------- Report/blend   -------------->
      <div class="cell is-col-span-1 is-flex is-align-items-center is-justify-content-center">
        <slot name="report" />
      </div>

      <!-- Rates, hours, total -->
      <div class="cell is-col-span-4 is-align-content-center">

        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">

            <!----------- Rates   -------------->
            <div class="cell is-col-span-4">
              <slot name="rates"
                v-if="isQuoteHour" />
            </div>
            <!---------- hour value ------------->
            <div class="cell is-col-span-4">
              <slot name="hour_value" />
            </div>
            <!---------- Total value ------------->
            <div class="cell is-col-span-4">
              <slot name="total_value" />
            </div>

          </div>
        </div>

      </div>

      <!---------- Actions ------------->
      <div class="cell is-col-span-2 end is-align-content-center">
        <slot name="comment" />
        <slot name="assessment_status" />
        <slot name="delete" />
        <slot name="draggable" />
      </div>

      <!-- Line No -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="lineNo" />
      </div>

      <!-- Item No -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemNo" />
      </div>

      <!-- Item Status -->
      <div class="cell is-col-span-1"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemStatus" />
      </div>

      <!-- Present as -->
      <div class="cell is-col-span-2 mt-2"
        v-if="isFullyExpanded">
        <slot name="present_as" />
      </div>

      <!-- empty if not a support user -->
      <div class="cell is-col-span-5"
        v-if="isFullyExpanded && !isSupportUser" />

      <!------ Paint group -------->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isPaintItem"
        v-tippy="{ content: 'Paint Group'}">
        <slot name="paint_group" />
      </div>
      <div v-else-if="isFullyExpanded"
        class="cell is-col-span-2" />

      <!-- empty -->
      <div class="cell is-col-span-3"
        v-if="isFullyExpanded" />

    </div>

  </div>

</template>

<script>
export default {
  name: 'QuoteBuilderLabourItemTemplate',
  props: {
    isHeader: {
      type: Boolean,
      default: true
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isFullyExpanded: {
      type: Boolean,
      default: false
    },
    // quote type dollar/hour
    isQuoteHour: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isPaintItem: {
      type: Boolean,
      default: false
    },
    isNtar: {
      type: Boolean,
      default: false
    },
    showPaintLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSupportUser() {
      return !!this.$userInfo.isSupportUser
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 2px solid $primary;
}
</style>