
export default {
  inject: ['$vv'],
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    validationItems() {
      switch (this.itemType) {
        case 'LAB':
          return this.$v.entity.labours
        case 'PRT':
          return this.$v.entity.parts
        case 'MISC':
          return this.$v.entity.miscs
        case 'SUBL':
          return this.$v.entity.sublets
        case 'OTHER':
          return this.$v.entity.others
      }
    }
  },
  methods: {
    isValidItemNo(quoteItemId) {
      if (this.validationItems) {
        const index = this.validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (this.validationItems.$each[index]) {
          return this.validationItems.$each[index].itemNo.required
        } else {
          return false
        }
      }
    },
    isValidItemDesc(quoteItemId) {
      if (this.validationItems) {
        const index = this.validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (this.validationItems.$each[index]) {
          return this.validationItems.$each[index].itemDesc.required
        } else {
          return false
        }
      }
    }
  }
}
