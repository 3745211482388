<template>
  <div>
    <!----------------------Sublets ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.SUBL || selectedViewType != viewTypes.tabbedView">
      <quote-builder-sublet-headings-component :count="subletLength"
        :total-amount="subletsTotal"
        :is-full-width="isFullWidth" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.SUBL)"
          v-model="innerValue.sublets"
          @over.prevent
          @enter.prevent
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :clone="$event => cloneHandler($event, ItemCategoryTypes.SUBL)"
          :group="{ name: ItemCategoryTypes.SUBL, pull: 'clone', put: true }"
          handle=".item-drag-handle">
          <quote-builder-sublet-item v-for="item in subletItems"
            :key="item.id"
            :inner-value="innerValue"
            :is-full-width="isFullWidth"
            :items-assessments="itemsAssessments"
            :item="item"
            :edit-item-id="editItemObj.id"
            :vehicle="vehicle"
            :sublets="sublets"
            :vendors="vendors"
            @focusNextRow="id => $emit('focusNextRow', id)"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
            @itemRowClicked="(value, event, close) => $emit('handleItemRowClick',value, event, close)" />
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem()">
          + Add Item
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteBuilderSubletHeadingsComponent from './QuoteBuilderSubletHeadingsComponent.vue'
import QuoteBuilderSubletItem from './QuoteBuilderSubletItem.vue'
import draggable from 'vuedraggable'
import { ItemCategoryTypes } from '@/enums'
import cloneDeep from 'lodash.clonedeep'
import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin } from '../../mixins'
import QuoteService from '../../.././QuoteService'
import VendorService from '@/views/vendor/VendorService'
import Guid from '../../../../../components/Guid'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'QuoteBuilderSubletComponent',
  components: {
    QuoteBuilderSubletHeadingsComponent,
    QuoteBuilderSubletItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      sublets: [],
      vendors: []
    }
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    subletItems() {
      return this.innerValue.sublets
    },
    subletLength() {
      return this.innerValue.sublets.filter((i) => !i.isDeleted).length
    },
    subletsTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.sublets), ItemCategoryTypes.SUBL)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemsAssessments() {
      return this.getItemsAssessments(cloneDeep(this.innerValue.sublets), cloneDeep(this.innerValue.quoteAssessments))
    }
  },
  mounted() {
    this.$v.entity.sublets.$touch()
  },
  created() {
    this.getSublets()

    this.getVendors()
  },
  methods: {
    cloneHandler(item, type) {
      // change the quoteItemId to a new guid so we can find it later and change to correct type on drop
      // TODO: looks like this doesn't work, leave it as is
      // item.quoteItemId = Guid.newGuid()
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.sublets.map((i) => i.sortNo))
      this.innerValue.sublets.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    handleItemAdded(item, type, index) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', item.itemDesc, ItemCategoryTypes.SUBL)
      newItem.lineNumber = this.innerValue.audatex === null ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.sortNo = this.innerValue.sublets.length ? Math.max(...this.innerValue.sublets.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.lines = this.nextLineNumber + 1
      // replace the item with the new item
      this.innerValue.sublets.splice(index, 1, newItem)

      this.$emit('onItemCopied', newItem)
    },
    async getSublets() {
      this.sublets = await QuoteService.getSubletsProto(!this.innerValue.audatex)
    },
    async getVendors() {
      const filter = {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }

      const vendors = await VendorService.getVendorDropdownProto2(filter)

      this.vendors = vendors
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.SUBL)
      newItem.lineNumber = this.innerValue.audatex === null ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.sortNo = this.innerValue.sublets.length ? Math.max(...this.innerValue.sublets.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    }
  }
}
</script>

<style scoped>
/* Your component's styles go here */
</style>