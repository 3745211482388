<template>
  <div class="item-row-quote-builder-misc quote-builder-item-row"
    :id="item.quoteItemId"
    :class="[{'has-background-grey-lighter is-selected' : isSelected, 'is-open' : isSelected && !isFullWidth}, defaultRowColourClass(item, getItemAssessments(item), displayDeleted), rowDisplayStatus(item, getItemAssessments(item)) ]"
    @click="event => handleClick(event)">

    <!--------------------------------------------------------------- Main Grid --------------------------------------->
    <quote-builder-misc-item-template :is-full-width="isFullWidth"
      :is-fully-expanded="isSelected && !isFullWidth"
      :is-selected="isSelected"
      @handleClick="handleClick">
      <template v-slot:description>
        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">
            <!------- Description ------->
            <div class="cell is-col-span-11 is-flex is-flex-direction-column"
              :class="[defaultCellColourClass(item,'itemDesc')]"
              :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
              v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
              <item-description-autocomplete v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && $company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
                v-model="item.itemDesc"
                v-focus-next:callback="handleTransitionNextRow"
                schedule-id=""
                body-id=""
                :id="item.quoteItemId + '-itemDesc'"
                :class-prop="(!isValidItemDesc(item.quoteItemId) ? 'is-danger ': '') + defaultRowColourClass(item, getItemAssessments(item))"
                :item-type="itemTypes.MISC"
                :times-style="itemTypes.MISC" />
              <input v-else-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !$company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
                class="input"
                :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                placeholder="Item Description"
                :id="item.quoteItemId + '-itemDesc'"
                v-model="item.itemDesc">
              <span v-else
                class="is-quote-item-field-editable"
                :id="item.quoteItemId + '-itemDesc'"
                :class="{ 'has-text-danger is-italic': !isValidItemDesc(item.quoteItemId) }">{{ item.itemDesc || 'Description' }}</span>
            </div>

          </div>
        </div>
      </template>

      <!------------------- Report Only ------------>
      <template v-slot:report_blend>
        <div class="is-flex is-clickable"
          :class="{'disabled' : item.deleteOtherItem || !canMakeReportOnly(item)}"
          @click="updateReport(item, $event)">
          <span v-tippy="{ theme: 'secondary', enabled: true }"
            content="Report"
            :class="item.reportOnly ? 'is-dark ' : 'is-light'"
            class="tag is-primary p-1 px-2">
            R
          </span>
        </div>
      </template>

      <!-------------------------------Total Value  ---------------------->
      <template v-slot:total_value>
        <div class="is-flex"
          :class="[defaultCellColourClass(item,'value')]"
          :content="getTooltipCommunicationHistoryForField(item, 'value')"
          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            class="input has-text-right"
            :id="item.quoteItemId + '-value'"
            :class="defaultRowColourClass(item, getItemAssessments(item))"
            v-model.number="item.value"
            v-focus-next:callback="handleTransitionNextRow"
            :disabled="item.quantityReceived>0 && !$userInfo.isSupportUser"
            :precision="2"
            :minus="true"
            @input="updateTotal(item)" />
          <span v-else
            :id="item.quoteItemId + '-value'"
            class="is-quote-item-field-editable">
            {{ $filters.formatCurrency(item.value, $userInfo.locale, false, 2) }}
          </span>
        </div>
      </template>

      <!------------------- Present As ------------>
      <template v-slot:present_as>
        <present-as-component :item="item"
          @handlePresentAs="$emit('handlePresentAs', item.quoteItemId)" />
      </template>

      <!---------------------- Comment   ---------------->
      <template v-slot:comment
        v-if="item.itemComment">
        <div v-tippy="{ theme: 'secondary', enabled: true, content:item.itemComment }">
          <span class="mdi has-text-primary is-primary mdi-18px mdi-message-reply-text mr-1 is-clickable" />
        </div>
      </template>

      <template v-slot:assessment_status>
        <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
          :item="item"
          :item-assessments="getItemAssessments(item)"
          :next-assessment-number="nextSupplemetryNo"
          :quoting-method="innerValue.quotingMethod"
          :icon-size="4"
          class="icon" />
      </template>

      <template v-slot:delete_button>
        <button class="button is-ghost tooltip is-align-items-center p-0 mx-1"
          data-tooltip="Delete Misc Item"
          @click="deleteItem(item)">
          <span class="mdi mdi-delete mdi-24px has-text-danger is-clickable" />
        </button>
      </template>

      <template v-slot:drag_handle>
        <button class="button is-ghost tooltip is-align-items-center p-0 mx-1 item-drag-handle"
          data-tooltip="Drag">
          <span class="mdi mdi-drag-vertical mdi-24px has-text-primary is-clickable" />
        </button>
      </template>

      <!----------------- Line No  ------------------------>
      <template v-slot:lineNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Line Number' }">
          <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
            class="input has-text-right"
            :class="defaultRowColourClass(item, getItemAssessments(item))"
            v-model.number="item.lineNumber"
            v-focus-next:callback="handleTransitionNextRow"
            :precision="0"
            separator="" />
          <span v-else>{{ item.lineNumber }}</span>
        </div>
      </template>

      <!----------------- Item No  ------------------------>
      <template v-slot:itemNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Number' }">
          <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item No."
            v-model="item.itemNo"
            v-focus-next:callback="handleTransitionNextRow">
          <span v-else>{{ item.itemNo }}</span>
        </div>
      </template>

      <!------------------Item Status  -------------------->
      <template v-slot:itemStatus>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Status' }">
          <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
            class="input is-capitalized"
            v-model="item.itemStatus"
            v-focus-next:callback="handleTransitionNextRow">
          <span v-else>{{ item.itemStatus }}</span>
        </div>
      </template>

    </quote-builder-misc-item-template>
  </div>
</template>

<script>
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import { ItemCategoryTypes, QuotingMethodTypes, QuoteItemStatusTypes, PartStatusTypes, PartTypes, ItemTypes } from '@/enums'
import QuoteBuilderMiscItemTemplate from '../../templates/QuoteBuilderMiscItemTemplate.vue'
import QuoteFiltersMixins from '../../../QuoteFiltersMixins'
import { QuoteItemValidationMixin, QuoteItemsMixin, QuoteItemDifferenceMixin } from '../../mixins'
import SupplementaryIcon from '../../../components/SupplementaryIcon.vue'
import PresentAsComponent from '../../components/PresentAsComponent.vue'
import ItemDescriptionAutocomplete from '../../../components/ItemDescriptionAutocomplete/ItemDescriptionAutocomplete.vue'
import { FocusNext } from '@/components/directives'

export default {
  name: 'QuoteBuilderMiscItem',
  directives: {
    FocusNext
  },
  components: { vueNumeric, QuoteBuilderMiscItemTemplate, SupplementaryIcon, PresentAsComponent, ItemDescriptionAutocomplete },
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  mixins: [QuoteFiltersMixins, QuoteItemsMixin, QuoteItemValidationMixin, QuoteItemDifferenceMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    // in a single row view, the row is full width
    isFullWidth: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    editItemId: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    },
    itemsAssessments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // your data goes here
      isPresentAsModalActive: false,
      itemType: 'MISC',
      displayDeleted: false,
      isFloatingWidgetActive: false
    }
  },
  computed: {
    // your computed properties go here
    isSelected() {
      return this.item.quoteItemId === this.editItemId
    },
    PartStatusTypes() {
      return PartStatusTypes
    },
    partTypes() {
      return PartTypes
    },
    itemTypes() {
      return ItemTypes
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  methods: {
    handleTransitionNextRow() {
      this.$emit('focusNextRow', this.item.quoteItemId)
    },
    closeModal() {
      this.isPresentAsModalActive = false
    },
    handleClick(event, close = false) {
      event.stopPropagation()
      this.$emit('itemRowClicked', this.item, event, close)
    },
    updateBlend(item) {
      item.blend = !item.blend

      const isBlend = item.blend
      const itemToUpdate = this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId)
      if (item.itemType === ItemCategoryTypes.PAINT && this.isNtar) {
        const value = isBlend ? roundAwayFromZero(item.hourValue * 0.7) : roundAwayFromZero(item.hourValue / 0.7)
        if (this.item.quotingMethod === QuotingMethodTypes.Dollar) {
          itemToUpdate.hourValue = value
          itemToUpdate.dollarValue = item.hourValue
        } else {
          itemToUpdate.hourValue = value
          itemToUpdate.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        }
        this.updateTotal()
      }
    },
    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.miscs
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.miscs.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    getItemAssessments(item) {
      const assessments = this.itemsAssessments
      const itemAssessments = assessments.find((i) => i.quoteItemId == item.quoteItemId)
      if (!!itemAssessments) return itemAssessments.assessments
      return []
    },
    updateReport(item, event) {
      // event.stopPropagation()
      if (!item.deleteOtherItem && !this.innerValue.readOnly && !this.readOnlyView && !this.audanetLock && this.canMakeReportOnly(item)) {
        item.reportOnly = !item.reportOnly
        // const itemToUpdate = this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId)
        // itemToUpdate.reportOnly = item.report
        // itemToUpdate.reportOnly = item.reportOnly
      }
    },
    updateTotal() {
      this.isFloatingWidgetActive = true
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.item-row-quote-builder-misc {
  height: 45px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 5px;
  padding: 2px;
  // align-content: center;
  /* align center vertically */
  // align-items: center;

  .center-y {
    display: flex;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }

  &.is-open {
    height: 85px !important;
  }
}

/* text overflow ellipses */
.item-row-quote-builder-misc span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>